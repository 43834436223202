<template>
  <AContainer class="b-about is-fluid is-paddingless mb-6 p-3" style="margin-top: -25px;">
  
    <ASectionTitle>
      <template v-slot:icon>
          <img
            class="is-hidden-tablet"
            src="../../assets/like-blue.svg"
            alt="Ícone benefícios"
          />
          <img
            class="is-hidden-mobile"
            src="../../assets/like-blue.svg"
            alt="Ícone benefícios BemPerto"
          />
        </template>
      <template v-slot:title> Sobre o programa </template>
    </ASectionTitle>
    <div
      class="o-about has-text-centered-mobile has-text-left-tablet mt-5 pb-6 pl-4 pr-4 pt-6"
      style=" background: radial-gradient(closest-side at 57% 19%, rgb(45, 156, 185) 12%, rgb(92, 86, 162) 140%) !important"
    >
    <div style="text-align: center;">
      <img src="../../assets/Bemperto_azul_branco.svg"
          style="margin-top: -15px; max-width: 26%; padding-bottom: 2%;"
          alt="O que o BemPerto traz para você"/>
    </div>

      <MVideo class="is-inline-block">
        <template v-slot:playButton>
          <img
            class="play"
            src="../../assets/play-button.svg"
            alt="Ícone Play"
          />
        </template>
        <source
          src="../../assets/00-Programa-Bemperto-Fevereiro24-novo.mp4"
          preload="none"
          type="video/mp4"
        />
      </MVideo>
      <AContent class="mt-6">
        <p>
          Somos um programa de suporte exclusivo para os pacientes
          Amgen<sup>®</sup>, que tem como objetivo oferecer todo o apoio para
          proporcionar uma
          <strong
            >melhor experiência durante o tratamento com nossos
            medicamentos</strong
          >. Aos pacientes, médicos e cuidadores, nosso programa oferece
          benefícios, informações e orientações sobre temas diversos
          relacionados a patologias e tratamentos dos programas participantes.
        </p>
        <AColumns  class="m-emphasis-block mt-3">
          <AColumn class="has-text-centered is-3-tablet is-2-desktop">
            <MEmphasis style="display: none" class="is-gapless">
              <template v-slot:first> 5 </template>
              <template v-slot:second> anos de atividade
                 </template>
            </MEmphasis>
          </AColumn>
          <AColumn class="has-text-centered">
            <MEmphasis style="display: none">
              <template v-slot:first>
                <img src="../../assets/big-smile.svg" alt="Ícone sorriso" />
              </template>
              <template v-slot:second>
                <div class="benefited-number">40.000</div>
                <div class="benefited-text">Pacientes beneficiados</div>
              </template>
            </MEmphasis>
          </AColumn>
        </AColumns>
        <p>
          A Amgen<sup>®</sup> entende e respeita a individualidade de cada um
          dos seus pacientes e oferece através de diferentes programas
          benefícios diversos como: enfermeira para a aplicação do medicamento
          em domicílio, apoio psicológico durante o tratamento de alguns tipos
          de câncer e equipamentos que possam abreviar a internação de pacientes
          para tratamento de algumas doenças. Nosso portal foi desenvolvido para
          que você encontre conteúdos específicos sobre a doença, hábitos de
          vidas e seus impactos, guia de aplicação, adesão ao tratamento e
          também dicas de saúde.
        </p>
        <p>
          Se você está em uso de um dos medicamentos da Amgen<sup>®</sup> e for
          elegível ao programa, você também contará com
          <strong>conteúdos exclusivos</strong> utilizando seu login e sua
          senha.
        </p>
      </AContent>
    </div>
  
  </AContainer>
</template>

<script>
import AColumn from "@/components/atoms/Column.vue";
import AColumns from "@/components/atoms/Columns.vue";
import AContainer from "@/components/atoms/Container.vue";
import AContent from "@/components/atoms/Content.vue";
import ASectionTitle from "@/components/molecules/SectionTitleWhite.vue";
import MEmphasis from "@/components/molecules/Emphasis.vue";
import MVideo from "@/components/molecules/Video.vue";

export default {
  components: {
    AColumn,
    AColumns,
    AContainer,
    AContent,
    ASectionTitle,
    MEmphasis,
    MVideo
  },
  name: "OAboutBemPerto"
};
</script>

<style lang="scss">
.b-about {
  color: #70CDED !important;
  .m-emphasis-block {
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
  }

  .m-emphasis {
    margin-left: auto;
    margin-right: auto;

    .benefited-number {
      font-size: 2rem;
    }

    .benefited-text {
      font-size: 0.5rem;
    }
  }
}

@include media(">=425px") {
  .b-about {
    .m-emphasis {
      .benefited-number {
        font-size: 3.2rem;
      }

      .benefited-text {
        font-size: 0.8rem;
      }
    }
  }
}

@include media(">=tablet") {
  .b-about {
    .m-emphasis {
      .benefited-number {
        font-size: 3rem;
      }
    }
  }
}

@include media(">=desktop") {
  .b-about {
    .m-emphasis {
      .benefited-number {
        font-size: 3.8rem;
      }

      .benefited-text {
        font-size: 1rem;
      }
    }
  }
}

.t-about .o-about {
  background-color: transparent !important;
}
</style>
