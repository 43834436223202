<template>
  <AContainer class="b-faq is-fluid is-paddingless mb-6 p-3">
    <MSectionTitle class="mb-5">
      <template v-slot:icon>
        <!-- <img
          src="../../assets/icones/interrogacao-white.svg"
          alt="Ícone Dúvidas frequentes"
        /> -->
        <img src="../../assets/info_v2.svg" alt="Ícone sobre a Amgen" />
      </template>
      <template v-slot:title> 
        <span style="color: #70CDED !important;">
          Dúvidas frequentes
        </span>
      </template>
    </MSectionTitle>
    <MAccordion :items="faqItems" />
  </AContainer>
</template>

<script>
import AContainer from "@/components/atoms/Container.vue";
import MSectionTitle from "@/components/molecules/SectionTitle.vue";
import MAccordion from "@/components/molecules/Accordion.vue";

export default {
  components: {
    AContainer,
    MAccordion,
    MSectionTitle
  },
  name: "OFAQ",
  setup() {
    const faqItems = [
      {
        id: 1,
        title: "1 – Como sei se sou elegível a participar de um dos programas?",
        body:
          "Para saber se você é elegível a participar de qualquer programa de suporte ao paciente, é necessário ter a prescrição médica para algum dos medicamentos Amgen<sup>®</sup> participantes dos programas. Importante que esta prescrição seja para uma das indicações descritas em bula aprovada no Brasil. No momento, temos programas para as seguintes doenças: Osteoporose, Leucemia Linfoide Aguda Tipo B (LLA B), Câncer colorretal e Doenças autoimunes."
      },
      {
        id: 2,
        title: "2 - O programa oferece algum benefício ao paciente?",
        body:
          "Sim, os programas oferecem diferentes benefícios, de acordo com as necessidades específicas dos pacientes de cada doença. Consulte os benefícios do seu programa!"
      },
      {
        id: 3,
        title:
          "3 - É preciso comprovar o uso do medicamento Amgen<sup>®</sup> para ter acesso aos benefícios?",
        body:
          "Sim, para fazer parte do programa e receber um benefício, você deve ter a prescrição de um medicamento Amgen<sup>®</sup> participante dos programas e será necessária a confirmação de alguns dados para cadastramento e comprovação de elegibilidade."
      },
      {
        id: 4,
        title:
          "4 - Se eu tiver alguma dúvida de aplicação do meu medicamento, tem alguém para me ajudar?",
        body:
          "Para os medicamentos injetáveis existe um suporte para orientação e aplicação do medicamento. Se você estiver com dúvidas sobre o manuseio ou aplicação, nossa central de atendimento direcionará sua dúvida para uma pessoa especializada no medicamento em questão."
      },
      {
        id: 5,
        title:
          "5 - Posso acessar o site e ter informações  sobre a doença que eu tenho?",
        body:
          "Sim, o site possui 2 tipos de informação: as informações sobre as doenças e dicas de saúde em geral, que poderão ser encontradas na área aberta do site. Já as informações que contém orientações sobre o uso e funcionamento de cada medicamento, estão disponíveis na área restrita do site, controlada por senha, elaborada especialmente para os pacientes em uso dos medicamentos, comprovado por uma prescrição médica. Os conteúdos foram elaborados especialmente pensando em você e podem ser acessados quantas vezes forem necessárias. Nosso conteúdo é atualizado periodicamente, trazendo sempre mais informação para você. Aproveite o conteúdo."
      },
      {
        id: 6,
        title: "6 - Fiz o login e não consigo acessar. E agora?",
        body:
          'Caso você tenha feito seu login e não se recorde da senha, na própria página você poderá recuperá-la. Basta clicar em “Esqueci minha senha”, inserir seu CPF e data de nascimento e, pronto. Você receberá em seu endereço de e-mail cadastrado um email para redefinir a sua senha. Caso tenha dificuldade em realizar esse procedimento, por favor entre em contato com a <a class="text-white text-underline" href="/fale-conosco" title="Fale conosco">equipe especializada</a> através da nossa central pelo telefone específico do seu programa.'
      },
      {
        id: 7,
        title: "7 - Quais vantagens eu tenho em me cadastrar no programa?",
        body:
          "Além dos benefícios diferenciados de cada programa, você conta com um conteúdo exclusivo dentro da página do programa. Alguns conteúdos somente podem ser disponibilizados após a prescrição do seu médico. Neste caso, este conteúdo é apresentado somente no ambiente seguro do site, após confirmação do seu login e senha. Estes conteúdos são direcionados para melhor entendimento de suas doenças e são muito didáticos."
      },
      {
        id: 8,
        title: "8 - Como sei se meus dados estarão seguros?",
        body:
          "Para todo e qualquer dado coletado pelo Programa BemPerto<sup>®</sup>, a Amgen<sup>®</sup> respeita as legislações em vigor e atende a lei de privacidade e proteção de dados, desta forma,  seus dados serão compartilhados somente com as pessoas que estão envolvidas no seu processo de cadastro, acompanhamento da doença, bem como o seu médico, para que se possa disponibilizar os benefícios que você concordar em receber. Para saber mais consulte a nossa política de privacidade disponível em <a href='https://www.amgen.com.br/privacy-statement' rel='noopener noreferrer' target='_blank' title='Política de Privacidade Amgen®'>https://www.amgen.com.br/privacy-statement</a>."
      },
      {
        id: 9,
        title: "9 - Para que serão utilizados os dados coletados no cadastro?",
        body:
          "Os dados coletados são utilizados apenas para fins de disponibilização dos benefícios oferecidos pelo programa. Estes poderão ser compartilhados com as empresas parceiras responsáveis pela prestação do serviço. Reforçamos que a Amgen<sup>®</sup> garante que estes dados serão armazenados seguindo as normas vigentes na lei."
      },
      {
        id: 10,
        title: "10 - Como cancelar meu cadastro no programa?",
        body:
          'A qualquer momento você poderá se descredenciar do programa. Basta ligar para nossa <a class="text-white text-underline" href="/fale-conosco" title="Fale conosco">central especializada</a> e fazer sua solicitação de forma simples, e sem burocracia.'
      },
      {
        id: 11,
        title: "11 - Como faço para entrar em contato com o atendimento?",
        body:
          'Você poderá acessar nosso atendimento através dos telefones disponíveis no link <a class="text-white text-underline" href="/fale-conosco" title="Fale conosco">Fale Conosco</a>.'
      }
    ];

    return {
      faqItems
    };
  }
};
</script>

<style lang="scss">
.text-white {
  color: white !important;
}

.text-underline {
  text-decoration: underline;
}

.b-faq {
  .m-accordion {
    background-color: #0275bf;
  }
}

@include media(">=desktop") {
  .b-faq {
    .m-accordion {
      border: 2px solid #ffffff40;
      border-radius: 10px;
      margin: 0 auto;
      max-width: 1334px;
      padding: 4rem;
      width: 80%;
    }
  }
}

@include media(">=widescreen") {
  .b-faq {
    .m-accordion {
      padding-left: 7rem;
      padding-right: 7rem;
    }
  }
}

@include media(">=fullhd") {
  .b-faq {
    .m-accordion {
      padding-left: 12rem;
      padding-right: 12rem;
    }
  }
}

.m-accordion__item__body {
    margin-top: -20px !important;
    border-radius: 7px !important;
}

.m-accordion__item__title {
  color: #156190;
  margin-top: 2px !important;
}
</style>
