<template>
  <AContainer
    class="b-programs has-text-centered is-fluid is-paddingless mb-6 p-3"
    id="participating-programs"
  >
    <ASectionTitle>
      <template v-slot:icon>
        <img
          src="../../assets/round-heart.svg"
          alt="Ícone sobre programa BemPerto®"
        />
      </template>
      <template v-slot:title>
        <span class="text-programa-participantes">Programas <br />
        participantes</span>
      </template>
    </ASectionTitle>
    <MTabs
      class="m-programs-tabs"
      :class="activeTab"
      :activeTab="activeTab"
      :tabs="tabs"
    />
    <div
      v-if="activeTab === 'osteoporose'"
      class="m-program-content"
      id="bemperto-osteoporose"
     
    >
      <div class="m-program-content__img"  style="border: 3px solid #906098 !important; border-radius: 6px !important;">
        <img
          src="../../assets/osteoporose.png"
          alt="Programa BemPerto osteoporose"
        />
      </div>
      <AContent class="m-program-content__desc mr-3 ml-3 pb-6 pl-5 pr-5 pt-6">
        <h3>Seus ossos agradecem.</h3>
        <p>
          A osteoporose afeta aproximadamente 500 milhões de pessoas em todo
          mundo.<sup>1</sup>
        </p>
        <p>
          O BemPerto<sup>®</sup> da Sua Liberdade foi idealizado pela Amgen<sup
            >®</sup
          >
          e vai trazer mais esclarecimentos sobre a doença. A ideia do programa
          é proporcionar mais conforto, oferecendo dicas de como conviver melhor
          com a osteoporose e ter uma rotina mais saudável e confortável no dia
          a dia.
        </p>
        <AButton
          class="p-4"
          component="external"
          :href="`${host}/liberdade`"
          title="Conheça o Programa BemPerto da Sua Liberdade"
        >
          Conheça
        </AButton> 
      </AContent>
      <AColumns class="is-centered is-gapless is-mobile">
        <AColumn class="is-full">
          <MReferences>
            <li style="color: #d1d1d1 !important;">
              International Osteoporosis Foundation. Epidemiology of
              osteoporosis and fragility fractures. Disponível em:
              <a
                href="https://www.osteoporosis.foundation/facts-statistics/epidemiology-of-osteoporosis-and-fragility-fractures"
                rel="noopener noreferrer"
                target="_blank"
                style="color: #d1d1d1 !important; font-weight: 800;"
                >https://www.osteoporosis.foundation/facts-statistics/epidemiology-of-osteoporosis-and-fragility-fractures</a
              >. Acessado em 04/01/2021.
            </li>
          </MReferences>
        </AColumn>
      </AColumns>
    </div>
    <div
      v-if="activeTab === 'cancer-colorretal'"
      class="m-program-content"
      id="bemperto-cancer-colorretal"
    >
      <div class="m-program-content__img">
        <img
          src="../../assets/programa-bemperto-cancer-colorretal.webp"
          alt="Programa BemPerto câncer colorretal"
        />
      </div>
      <AContent class="m-program-content__desc mr-3 ml-3 pb-6 pl-5 pr-5 pt-6">
        <h3>Câncer colorretal.</h3>
        <p>
          BemPerto<sup>®</sup> do seu amanhã é um programa exclusivo da
          Amgen<sup>®</sup> para suporte gratuito aos pacientes em tratamento de
          câncer colorretal metastático (câncer de intestino) em pacientes com
          um tipo específico de tumor conhecido como "Tumor RAS tipo selvagem".
        </p>
        <p>
          Para nós, estar bem perto significa acolher os pacientes e cuidadores,
          tratando-os com atenção e oferecendo orientações e suporte de
          qualidade.
        </p>
        <AButton
          class="p-4"
          component="external"
          :href="`${host}/amanha`"
          title="Conheça o Programa BemPerto do seu amanhã"
        >
          Conheça
        </AButton>
      </AContent>
    </div>
    <div
      v-if="activeTab === 'leucemia-linfoblastica-aguda'"
      class="m-program-content"
      id="bemperto-leucemia-linfoblastica-aguda"
    >
      <div class="m-program-content__img">
        <img
          src="../../assets/programa-bemperto-leucemia-linfoblastica-aguda.png"
          alt="Programa BemPerto Leucemia Linfoblástica Aguda"
        />
      </div>
      <AContent class="m-program-content__desc mr-3 ml-3 pb-6 pl-5 pr-5 pt-6">
        <h3>Leucemia Linfoblástica Aguda B.</h3>
        <p>
          Seja muito bem-vindo ao Programa BemPerto<sup>®</sup> de Quem Você
          Ama. Esse é um programa gratuito para pacientes que estão em
          tratamento para leucemia linfoblástica aguda (LLA B).
        </p>
        <p>
          Um programa que acolhe os pacientes e cuidadores, tratando todos com
          cuidado e muito carinho. Se você é um paciente de LLA B e deseja
          participar do nosso programa, será um prazer poder fazer parte da sua
          jornada de tratamento.
        </p>
        <p>Nós estamos aqui, sempre BemPerto<sup>®</sup> de você.</p>
        <AButton
          class="p-4"
          component="external"
          :href="`${host}/dequemvoceama`"
          title="Conheça o Programa BemPerto de Quem Você Ama"
        >
          Conheça
        </AButton>
      </AContent>
    </div>
    <div
      v-if="activeTab === 'doenca-autoimune'"
      class="m-program-content"
      id="bemperto-doenca-autoimune"
    >
      <div class="m-program-content__img">
        <img
          src="../../assets/programa-bemperto-doenca-autoimune.webp"
          alt="Programa BemPerto Doença Autoimune"
        />
      </div>
      <AContent class="m-program-content__desc mr-3 ml-3 pb-6 pl-5 pr-5 pt-6">
        <h3>Sua saúde agradece</h3>
        <p>
          Obrigado por nos deixar acompanhar sua jornada de tratamento. A partir
          de agora estaremos sempre aqui, BemPerto<sup>®</sup> do seu bem-estar!
        </p>
        <p>
          O BemPerto<sup>®</sup> do seu bem-estar é um programa da Amgen
          destinado aos pacientes em tratamento de doenças autoimunes.
        </p>
        <p>
          Para nós, estar bem perto significa apoiar médicos, pacientes e
          cuidadores, proporcionando atenção, orientação e suporte de qualidade,
          buscando o melhor resultado durante o tratamento da doença.
        </p>
        <AButton
          class="p-4"
          component="external"
          :href="`${host}/bemestar`"
          title="Conheça o Programa BemPerto do seu bem-estar"
        >
          Conheça
        </AButton>
      </AContent>
    </div>
  </AContainer>
</template>

<script>
import { ref } from "vue";
import useTabHandler from "@/composables/useTabHandler";
import AButton from "@/components/atoms/Button.vue";
import AColumn from "@/components/atoms/Column.vue";
import AColumns from "@/components/atoms/Columns.vue";
import AContainer from "@/components/atoms/Container.vue";
import AContent from "@/components/atoms/Content.vue";
import ASectionTitle from "@/components/molecules/SectionTitle.vue";
import MReferences from "@/components/molecules/References.vue";
import MTabs from "@/components/molecules/Tabs.vue";

export default {
  components: {
    AButton,
    AColumn,
    AColumns,
    AContainer,
    AContent,
    ASectionTitle,
    MReferences,
    MTabs
  },
  name: "OParticipatingPrograms",
  setup() {
    const host = `//${document.location.hostname}`;
    const activeTab = ref("osteoporose");
    const tabs = [
      {
        id: "osteoporose",
        title: "Ossos",
        action: () => useTabHandler("osteoporose", activeTab, onChangeTab)
      },
      {
        id: "cancer-colorretal",
        title: "Intestino",
        action: () => useTabHandler("cancer-colorretal", activeTab, onChangeTab)
      },
      {
        id: "leucemia-linfoblastica-aguda",
        title: "Medula Óssea",
        action: () =>
          useTabHandler("leucemia-linfoblastica-aguda", activeTab, onChangeTab)
      },
      {
        id: "doenca-autoimune",
        title: "Autoimune",
        action: () => useTabHandler("doenca-autoimune", activeTab, onChangeTab)
      }
    ];

    function onChangeTab(tabName) {
      activeTab.value = tabName;
    }

    return {
      activeTab,
      host,
      tabs
    };
  }
};
</script>

<style lang="scss">
.m-programs-tabs {
  border-radius: 5px;
  box-shadow: 0 12px 20px#00000053;
  max-width: 90%;
  overflow-x: auto;
  position: relative;
  top: 18px;
  z-index: 4;

  &.osteoporose {
    background-color: #906098;
    font-weight: 600;
    li {
      &.is-active {
        a {
          color: #5F59A3;
        }
      }
    }

    & + .m-program-content {
      .m-program-content {
        &__img {
          border: 1px solid #906098 !important;

          &::before {
            background: linear-gradient(
              90deg,
              rgba(255, 255, 255, 0) 40%,
              #906098 100%
            );
          }
        }

        &__desc {
          *:not(.a-button) {
            color: #906098;
          }

          .a-button {
            background-color: #906098;

            &:hover {
              color: white;
            }
          }
        }
      }
    }
  }

  &.cancer-colorretal {
    background-color: #a498f3;

    li {
      &.is-active {
        a {
          color: #a498f3;
        }
      }
    }

    & + .m-program-content {
      .m-program-content {
        &__img {
          border: 3px solid #a498f3;

          &::before {
            background: linear-gradient(90deg, rgba(255, 255, 255, 0) 40%, #a498f3 100%) !important
          }
        }

        &__desc {
          *:not(.a-button) {
            color: #a498f3;
          }

          .a-button {
            background-color: #a498f3;
            &:hover {
              color: #a498f3;
            }
          }
        }
      }
    }
  }

  &.leucemia-linfoblastica-aguda {
    background-color: #78bbef;

    li {
      &.is-active {
        a {
          color: #78bbef;
        }
      }
    }

    & + .m-program-content {
      .m-program-content {
        &__img {
          border: 3px solid #78bbef;

          &::before {
            background: linear-gradient(
              90deg,
              rgba(255, 255, 255, 0) 40%,
              #78bbef 100%
            ) !important
          }
        }

        &__desc {
          *:not(.a-button) {
            color: #78bbef;
          }

          .a-button {
            background-color: #78bbef;

            &:hover {
              color: #78bbef;
            }
          }
        }
      }
    }
  }

  &.doenca-autoimune {
    background-color: #54d6fc;

    li {
      &.is-active {
        a {
          color: #54d6fc;
        }
      }
    }

    & + .m-program-content {
      .m-program-content {
        &__img {
          border: 3px solid #54d6fc;

          &::before {
            background: linear-gradient(
              90deg,
              rgba(255, 255, 255, 0) 40%,
              #54d6fc 100%
            ) !important;
          }
        }

        &__desc {
          *:not(.a-button) {
            color: #54d6fc;
          }

          .a-button {
            background-color: #54d6fc;

            &:hover {
              color: #54d6fc;
            }
          }
        }
      }
    }
  }

  a {
    color: $white;
    padding: 1.2rem;
    font-size: pxToEm(12px);
  }

  .m-tabs {
    &__spot {
      background-color: #fff;
      border-radius: 5px;
      box-shadow: 0 6px 12px#00000053;
      height: calc(100% - 8px);
      left: 4px;
      position: absolute;
      top: 50%;
      transition: left 0.5s ease-in-out;
      transform: translateY(-50%);
    }
  }
}

.m-program-content {
  position: relative;

  &__img {
    background-color: white;
    border-radius: 6px;
    border-style: solid;
    border-width: 3px;
    overflow: hidden;
    padding-top: 56.25%;
    position: relative;

    &::before {
      @extend %position-absolute;
      @extend %pseudo-el;
      @extend %zero-position;
      display: block;
      height: 100%;
      width: 100%;
      z-index: 2;
    }

    img {
      @extend %position-absolute;
      @extend %zero-position;
      width: 100%;
    }
  }

  &__desc {
    background: rgb(255, 255, 255);
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.6643032212885154) 0%,
      rgba(255, 255, 255, 0.8379726890756303) 15%,
      rgba(255, 255, 255, 1) 36%,
      rgba(255, 255, 255, 1) 100%
    );
    border-radius: 5px;
    box-shadow: 0 14px 32px#00000027;
    position: relative;
    top: -40px;
    z-index: 3;
  }

  .a-button {
    @extend %position-absolute;
    @extend %translate-x-center;
    bottom: -24px;
    color: $white;
    display: inline-block;
    text-transform: capitalize !important;
    min-width: 178px;

    &:hover {
      background-color: white !important;
    }
  }
}

@include media(">=tablet") {
  .m-programs-tabs {
    top: 28px;

    a {
      padding: 1.4rem;
    }
  }

  .m-program-content {
    &__desc {
      top: -100px;
    }
  }
}

@include media(">=desktop") {
  .m-programs-tabs {
    top: 38px;

    a {
      font-size: pxToEm(16px);
      padding: 1.6rem;
    }

    &.osteoporose {
      & + .m-program-content {
        .m-program-content {
          &__img {
            &::before {
              background: linear-gradient(
                90deg,
                rgba(255, 255, 255, 0) 50%,
                #906098 75%
              );
            }
          }
        }
      }
    }

    &.cancer-colorretal {
      & + .m-program-content {
        .m-program-content {
          &__img {
            &::before {
              background: linear-gradient(
                90deg,
                rgba(255, 255, 255, 0) 50%,
                #a498f3 75%
              );
            }
          }
        }
      }
    }

    &.leucemia-linfoblastica-aguda {
      & + .m-program-content {
        .m-program-content {
          &__img {
            &::before {
              background: linear-gradient(
                90deg,
                rgba(255, 255, 255, 0) 50%,
                #78bbef 75%
              );
            }
          }
        }
      }
    }

    &.doenca-autoimune {
      & + .m-program-content {
        .m-program-content {
          &__img {
            &::before {
              background: linear-gradient(
                90deg,
                rgba(255, 255, 255, 0) 50%,
                #54d6fc 75%
              );
            }
          }
        }
      }
    }
  }

  .m-program-content {
    &__desc {
      position: absolute;
      right: 5%;
      top: 50%;
      transform: translateY(-50%);
      width: 45%;
    }
  }
}

@include media(">=widescreen") {
  .container.b-programs {
    margin-top: -260px;
    max-width: 90% !important;

    .m-section-title {
      display: none;
    }
  }

  .m-programs-tabs {
    max-width: 96%;

    &.osteoporose {
      & + .m-program-content {
        .m-program-content {
          &__img {
            &::before {
              background: linear-gradient(
                90deg,
                rgba(255, 255, 255, 0) 40%,
                #906098 54%
              );
            }
          }
        }
      }
    }

    &.cancer-colorretal {
      & + .m-program-content {
        .m-program-content {
          &__img {
            &::before {
              background: linear-gradient(
                90deg,
                rgba(255, 255, 255, 0) 40%,
                #a498f3 54%
              );
            }
          }
        }
      }
    }

    &.leucemia-linfoblastica-aguda {
      & + .m-program-content {
        .m-program-content {
          &__img {
            &::before {
              background: linear-gradient(
                90deg,
                rgba(255, 255, 255, 0) 50%,
                #78bbef 66%
              );
            }
          }
        }
      }
    }

    &.doenca-autoimune {
      & + .m-program-content {
        .m-program-content {
          &__img {
            &::before {
              background: linear-gradient(
                90deg,
                rgba(255, 255, 255, 0) 40%,
                #54d6fc 54%
              );
            }
          }
        }
      }
    }
  }

  .m-program-content {
    &__img {
      padding-top: 50%;

      img {
        height: 100%;
        width: auto;
      }
    }

    &__desc {
      width: 50%;
    }
  }
}

@include media(">=fullhd") {
  .m-program-content {
    &__img {
      padding-top: 40%;
    }
  }
}
//novos css
.m-programs-tabs.osteoporose {
  background: #807BC3 !important; 
  box-shadow: 0px 12px 20px #00000033 !important;
  border-radius: 5px !important;
  opacity: 1 !important;
}

@media (min-width: 1024px){
  .m-programs-tabs.osteoporose + .m-program-content .m-program-content__img::before{
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 50%, #807BC3 75%) !important;
  } 
}

@media (min-width: 1216px){
.m-programs-tabs.osteoporose + .m-program-content .m-program-content__img::before {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 40%, #807BC3 54%) !important;
  }
}

.m-programs-tabs.osteoporose + .m-program-content .m-program-content__desc .a-button {
    background-color: #5F59A3 !important;
}

.m-programs-tabs.osteoporose + .m-program-content .m-program-content__desc *:not(.a-button) {
    color: #5E58A3 !important;
}

.m-programs-tabs.osteoporose + .m-program-content .m-program-content__img {
    border-color: #5F59A3;
    border-style: none !important;
    border-radius: 1px !important;
}

.m-programs-tabs.osteoporose + .m-program-content .m-program-content__desc .a-button {
  font-weight: 600 !important;
}
</style>
